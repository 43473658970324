import {
    addListener, app,
    appChange,
    CookieUser, getApps, getTokenFromUser,
    handleRoute,
    handleUserLogin, initSSO, post, queries,
    set,
    setActiveView,
    setMeta, SSOApp,
    translate, UserVerify,
    Vars,
    WordTranslations
} from '../global';

const texts: WordTranslations = {
    'Til login': {
        en: 'To login'
    },
    'Bruk en annen bruker': {
        en: 'Use another user'
    },
    'Navn Ukjent': {
        en: 'No Name'
    },
    'Ingen e-post': {
        en: 'No email'
    }
};
translate.addTexts(texts);

const buttonsHTML = (apps: SSOApp[]) => apps.map((app) => {
    return `
        <button class="app-button no-style" data-app="${app._id}">
            <img id="${app._id}" crossorigin="anonymous" width="100" height="100" class="app" src="${app.logo_square || app.logo}" alt="${app.name} logo" />
        </button>
    `;
}).join('');

async function handleUserSelect(token: string) {
    if (!app.initialized) return;
    const cookie_user = Vars.user;
    if (cookie_user) {
        try {
            await handleUserLogin(cookie_user, token);
        } catch (e) {
            handleRoute('login.html');
        }
    }
}

function handleAppChange(e: Event) {
    const setApp = (e.target! as HTMLDivElement).dataset.app;
    return appChange(setApp as string);
}

function setUser(user: CookieUser, token: string) {
    const userList = document.getElementsByClassName('user-list')[0];
    let html = '';

    const image = user.profileImage
        ? `<img src="${user.profileImage}" alt="profile_image" class="user-image" crossorigin="anonymous" />`
        : `<div class="user-image">${user.name[0]}</div>`;
    html += `
        <button type="button" class="user-item user-select" id="user-item" data-email="${user.email || ''}">
            <div class="user-image-wrapper">
                ${image}
            </div>
            <div class="user-info">
                <div class="user-name">${user.name || translate.t('Navn Ukjent')}</div>
                <div class="user-email">${user.email || translate.t('Ingen e-post')}</div>
            </div>
        </button>
    `;

    html += `
        <button id="admin-user-accounts" class="secondary">
            ${translate.t('Administrer brukerkonto')}
        </button>
    `;
    userList.innerHTML = html;
    addListener('user-item', 'click', () => handleUserSelect(token));
}

(async function() {
    await initSSO();

    const email = queries.get('email');
    if (email) {
        Vars.email = email;
    }

    if (queries.get('register')) {
        return handleRoute('register.html');
    }

    if (app.error) {
        document.getElementById('container')!.classList.remove('hidden');
        return;
    }

    if (!Vars.user) {
        if (app.initialized) {
            if (email) {
                try {
                    await post<UserVerify>('validate', {email});
                    return handleRoute('login.html');
                } catch (e) {
                    return handleRoute('register.html');
                }
            }
            return handleRoute('login.html');
        } else {
            setActiveView(1);
            const apps = await getApps();
            document.getElementById("app-list")!.innerHTML = buttonsHTML(apps);
        }
    } else {
        try {
            const token = await getTokenFromUser(Vars.user);
            if (token) {
                if (!app.initialized) {
                    const apps = await getApps();
                    document.getElementById("top-apps")!.innerHTML = buttonsHTML(apps);
                } else if (queries.get('autoLogin')) {
                    return handleUserSelect(token);
                }
                setUser(Vars.user, token);
            }
        } catch (e) {
            Vars.user = null;
            return handleRoute('login.html');
        }
    }

    document.getElementById('container')!.classList.remove('hidden');
    addListener('to-login', 'click', () => handleRoute('login.html'));
    addListener('login', 'click', () => handleRoute('login.html'));
    addListener('admin-user-accounts', 'click', () => handleRoute('update.html'));

    const buttons = document.getElementsByClassName('app-button');
    for (let key in buttons) {
        if (buttons.hasOwnProperty(key)) {
            buttons[key].addEventListener('click', handleAppChange);
        }
    }

    setMeta(translate.t('Logg inn til Aptly, Inwise eller Profiler som er utviklet av Ludens Gruppen AS.'));
    set('header', translate.t('Velg bruker'));
    set('header-2', translate.w('Platformer'));
    set('to-login', translate.t('Til login'));
})();
