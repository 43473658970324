import {app, isMobile, Vars} from "./init";
import {translate} from "./translate";

export function handleRoute(route: string) {
    window.location.href = route;
}

export async function appChange(appName: string) {
    const prev = app.initialized && document.getElementById(app.name);
    const next = document.getElementById(appName);

    if (prev && next) {
        prev.classList.remove('active');
    }
    if (next) {
        next.classList.add('active');
    }

    return app.change(appName);
}

export const createHTMLError = (error: string, actionLabel = translate.t('Tilbake til login'), actionID = 'login') => `
    <div class="center">
        <p class="center">${error}</p>
        <button id="${actionID}">${actionLabel}</button>
    </div>
`;


export function setActiveView(index: 0 | 1) {
    const wrapper = document.getElementsByClassName('content-wrapper')[0] as HTMLDivElement;
    const content = document.getElementsByClassName('content');
    const translate = isMobile ? '-100vw' : '-450px';
    Vars.activeView = index;

    if (content[0].classList.contains('hidden')) {
        content[0].classList.remove('hidden');
        setTimeout(() => {
            content[1].classList.add('hidden');
        }, 250);
    } else {
        content[1].classList.remove('hidden');
        setTimeout(() => {
            content[0].classList.add('hidden');
        }, 250)
    }

    if (index === 1) {
        wrapper.style.transform = `translate3d(${translate}, 0, 0)`;
    } else {
        wrapper.style.transform = ``;
    }
}

export function addListener(id: string, event: string, action: (e: Event) => void) {
    const elem = document.getElementById(id);
    if (elem) {
        elem.addEventListener(event, action);
    }
}

export function setLogos(logo: string) {
    const logoDivs = document.getElementsByClassName('logo')!;
    for (const key in logoDivs) {
        if (logoDivs.hasOwnProperty(key)) {
            logoDivs[key].innerHTML = `<img src="${logo}" alt="logo" crossorigin="anonymous" />`;
        }
    }
}

export function wait(ms: number) {
    return new Promise((res) => setTimeout(res, ms));
}

export function isIE() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        // @ts-ignore
        return !!window.MSInputMethodContext && !!document.documentMode;
    }
    return false;
}