import TranslateMinify from "./transminify.js";
import {DEFAULT_LOCALE, LOCALE} from "./init";

export const translate = new TranslateMinify({
    defaultLocale: DEFAULT_LOCALE,
    locale: LOCALE,
    words: {
        'Bekreftet': {
            en: 'Confirmed'
        },
        'Tilbake': {
            en: 'Back'
        },
        'Neste': {
            en: 'Next'
        },
        'Passord': {
            en: 'Password'
        },
        'Bekreft passord': {
            en: 'Confirm password'
        },
        'Gammelt passord': {
            en: 'Old password'
        },
        'Logg inn': {
            en: 'Login'
        },
        'Logg på': {
            en: 'Log on'
        },
        'Logg ut': {
            en: 'Logout'
        },
        'Lagre': {
            en: 'Save'
        },
        'Endre': {
            en: 'Change'
        },
        'E-post': {
            en: 'Email'
        },
        'Platformer': {
            en: 'Platforms'
        },
        'Registrer': {
            en: 'Register'
        },
        'Fullfør': {
            en: 'Complete'
        },
        'Glemt password': {
            en: 'Forgot password'
        },
        'Tilbakestill': {
            en: 'Reset'
        },
        'Nytt passord': {
            en: 'New password'
        },
        'Bekreft': {
            en: 'Confirm'
        },
        'Google': {
            en: 'Google'
        },
        'Fullt navn': {
            en: 'Full name'
        },
        'Fornavn': {
            en: 'First name'
        },
        'Etternavn': {
            en: 'Last name'
        },
        'Send e-post': {
            en: 'Send email'
        },
        'Send e-post igjen': {
            en: 'Send email again'
        },
        'E-post sent': {
            en: 'Email sendt'
        },
        'Norsk': {
            en: 'Norwegian'
        },
        'Engelsk': {
            en: 'English'
        },
        'Språk': {
            en: 'Language'
        },
        'Telefon': {
            en: 'Phone'
        }
    },
    texts: {
        'Mangler kode i url': { en: 'Missing code in url' },
        'Login vellykket. Vent til vi overfører deg til appen.': {
            en: 'Login success. Redirecting you to the app.'
        },
        'Tilbake til login': {
            en: 'Back to login'
        },
        'Til login': {
            en: 'To login'
        },
        'Til {{app}}': {
           en: 'To {{app}}'
        },
        'Du har logget deg inn. Men vi mangler litt informasjon om deg for å fortsette. Vennligst fyll ut feltene over.': {
            en: 'Login success. We are missing some information about you. Please fill out the fields above.'
        },
        'Passordet må være lengre en {{length}} karakterer': {
            en: 'Password must be {{length}} characters or more'
        },
        'Passordene samsvarer ikke': {
            en: 'Passwords does not match'
        },
        'Feil passord. Prøv igjen, eller klikk på glemt passord for å tilbakestille det.': {
            en: 'Wrong password. Try again, or press Forgot password to reset your password'
        },
        'E-post er allerede registrert. Prøv å logg inn.': {
            en: 'Email already registered. Try to login'
        },
        'Vi fant deg ikke. Se etter skrivefeil eller registrer deg ved å trykke "Ny bruker".': {
            en: 'We could\'nt find you. Check for a typo, or create a user by click "New user".'
        },
        'Innlogingsøkten timet ut. Prøv igjen.': {
            en: 'Login timed out. Try again.'
        },
        'Mangler data for spørring. Fyll inn alle felt': {
            en: 'Missing data. Please fill all fields'
        },
        'Nøkkel er ikke gyldig. Spør om en ny nøkkel for å oppdatere info.': {
            en: 'Key is not valid. Try resending the reset email.'
        },
        'Klarte ikke lage bruker. Prøv igjen.': {
            en: 'Failed to create user. Try again'
        },
        'Spørring feilet. Prøv operasjonen igjen.': {
            en: 'Request failed. Try again.'
        },
        'E-post er lik den registrerte.': {
            en: 'Email is equals to the registered.'
        },
        'En annen bruker er allerede registrert med denne e-posten. Prøv en annen.': {
            en: 'This email is already registered on an user. Try another email.'
        },
        'Oppdaterinslinken er allerede brukt eller utgått.': {
            en: 'Update link is already used or has timed out'
        },
        'Kode er ygyldig eller utgått': {
            en: 'Token is invalid or has expired'
        },
        'Kommunikasjon med server feilet. Prøv igjen.': {
            en: 'Communication with server failed. Try again'
        },
        'Feilet med parsing av data.': {
            en: 'Failed to parse data.'
        },
        'Ingen kontakt med server': {
            en: 'No contact with server'
        },
        'Logg inn til Aptly, Inwise eller Profiler som er utviklet av Ludens Gruppen.': {
            en: 'Log in to Apty, Inwise or Profiler. Developed by Ludens Gruppen'
        },
        'Logg inn med google': {
            en: 'Log in with google'
        },
        'Logg inn med bankid': {
            en: 'Log in with bankid'
        },
        'Hmm, login feilet ¯\\_(ツ)_/¯. Prøv igjen.': {
            en: 'Login failed ¯\\_(ツ)_/¯. Try again.'
        },
        'Administrer brukerkontoer': {
            en: 'Administrate user accounts'
        },
        'Administrer brukerkonto': {
            en: 'Administrate user account'
        },
        'Velg bruker': {
            en: 'Select user'
        },
        'Hvis du har logget inn tidligere, fyll inn e-posten på loginsiden for å logge inn.': {
            en: 'If you have logged in earlier, fill in your email on the login page to continue.'
        },
        'Registrer bruker': {
            en: 'Register user'
        },
        'Ingen er registrert med denne {{type}} brukeren. Tenkte du å registrere er bruker?': {
            en: 'No user found registered with this {{type}}. Did you want to register one?'
        },
        'App med navn "{{appName}}" ikke funnet': {
            en: 'App with name "{{appName}}" not found'
        },
        'Trenger navn for å registrere ny bruker': {
            en: 'Name is required to register'
        }
    }
});

export const set = (id: string, content: string, aria?: string) => {
    const doc = document.getElementById(id)!;
    doc.innerText = content;
    if (aria)
        doc.setAttribute('aria-label', aria);
};

export const setHtml = (id: string, content: string) => {
    const doc = document.getElementById(id);
    if (doc) doc.innerHTML = content;
};

export const setMeta = (meta: string) => {
    document.querySelector('meta[name="description"]')!.setAttribute("content", meta);
};