import {CookieUser, SSOApp} from "./types";
import {handleRoute, isIE, setLogos} from "./ui";
import {get, getGoldenTicket, getRefreshedToken} from "./ssoApi";
import {translate} from "./translate";

const path = window.location.pathname;

export const VALID_LOCALES = ['no', 'en'];
export const SSO_WEB_AUDIENCE = 'sso-web';
export const PRODUCTION = process.env.NODE_ENV === 'production';
export const isIndex = path === '/' || path.match(/\/index/);
export const getFileUrl = (path: string) => `${window.location.origin}/${path}`;
export const isUpdate = path.match(/^\/update.html/);
export const url = process.env.API_URL;
export const isMobile = window.innerWidth <= 600;
const LOGO = getFileUrl('files/logo.png');

export const queries = new URLSearchParams(window.location.search);
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const emailError = 'E-posten er ikke gyldig (post@domene.no)';

type Fields = 'phone' | '!phone';
export class Vars {

    static get activeView(): 0 | 1 {
        return (Number(localStorage.getItem('activeView')) || 0) as 0 | 1
    }
    static set activeView(value: 0 | 1) {
        localStorage.setItem('activeView', String(value));
    }

    static get app() {
        return JSON.parse(localStorage.getItem('app')!) as SSOApp;
    }
    static set app(app: SSOApp) {
        localStorage.setItem('app', JSON.stringify(app));
    }

    static get logo() {
        return localStorage.getItem('logo') || LOGO;
    }
    static set logo(logo: string) {
        localStorage.setItem('logo', logo);
    }

    static get verify() {
        return localStorage.getItem('verify') === 'true';
    }
    static set verify(confirm: boolean) {
        localStorage.setItem('verify', confirm ? 'true' : '');
    }

    static get authenticate() {
        return localStorage.getItem('auth') === 'true';
    }
    static set authenticate(auth: boolean) {
        localStorage.setItem('auth', auth ? 'true' : '');
    }

    static get code() {
        return localStorage.getItem('code') || '';
    }
    static set code(code: string) {
        localStorage.setItem('code', code);
    }

    static get email() {
        return (localStorage.getItem('email') || '');
    }
    static set email(email: string | null) {
        localStorage.setItem('email', email || '');
    }

    static get scope(): string {
        return (localStorage.getItem('scope') || '');
    }
    static set scope(scope: string | null) {
        localStorage.setItem('scope', scope || '');
    }

    static get locale() {
        const locale = localStorage.getItem('locale') || DEFAULT_LOCALE;
        return VALID_LOCALES.some(x => x === locale) ? locale : DEFAULT_LOCALE;
    }

    static set locale(locale: string) {
        if (VALID_LOCALES.some(x => x === locale)) {
            localStorage.setItem('locale', locale);
        } else {
            localStorage.setItem('locale', DEFAULT_LOCALE);
        }
    }

    static get fields(): Fields[] {
        const fields = localStorage.getItem('fields');
        return fields ? fields.split(',') as Fields[] : [];
    }

    static set fields(fields: Fields[]) {
        localStorage.setItem('fields', fields.join(','));
    }

    static get user(): CookieUser | null {
        const cookies = document.cookie;
        const cookie = cookies.match(/sso_token=([^;]*)/);
        if (cookie) {
            let parsed: CookieUser[] | CookieUser = JSON.parse(Buffer.from(cookie[1], 'base64').toString('utf-8'));
            parsed = Array.isArray(parsed) ? parsed[0] : parsed;
            if (parsed && new Date(parsed.expires) > new Date()) {
                return parsed;
            }
            return null;
        }
        return null;
    }

    static set user(user: CookieUser | null) {
        document.cookie = ('sso_token=' + Buffer.from(JSON.stringify(user), 'utf-8').toString('base64')) + (PRODUCTION ? ';secure' : '');
    }
}

class App {
    readonly _name: string = '';
    public initialized: boolean = false;
    private data?: SSOApp;
    readonly _validProviders = ['google', "microsoft"];
    readonly client_id: string | undefined;
    private _redirect_uri: string = '';
    public error: string = '';

    constructor() {
        try {
            const appData = localStorage.getItem('app');
            this._name = queries.get('app') || '';
            this.client_id = queries.get('client_id') || '';
            this._redirect_uri = queries.get('redirect_uri') || '';

            if (appData) {
                this.data = JSON.parse(appData) as SSOApp;
                const nameNotEqual = (!this._name || (this.data.name !== this._name));
                const redirectNotMatched = this._redirect_uri && (this._redirect_uri !== this.data.redirect_uri);

                if (this.client_id && !this.data.client) {
                    localStorage.removeItem('app');
                    this.data = undefined;
                } else if (isIndex && (nameNotEqual || redirectNotMatched)) {
                    localStorage.removeItem('app');
                    this.data = undefined;
                } else if(!this._name || (this._name === this.data.name)) {
                    this.initialized = true;
                    this.initSettings(this.data);
                }

            } else if(!this._name) {
                localStorage.removeItem('app');
            }
        } catch (e) {
            // empty
        }

        this.redirectToIndex = this.redirectToIndex.bind(this);
        this.redirectUserToApp = this.redirectUserToApp.bind(this);
    }

    get name() {
        if (!this.data) {
            throw new Error('App not initialized. need valid name')
        }
        return this.data.name;
    }
    get label() {
        if (!this.data) {
            throw new Error('App not initialized. need valid name')
        }
        return this.data.label || this.data.name;
    }
    get redirectUri() {
        if (!this.data?.redirect_uri) {
            throw new Error('App not initialized. need valid redirect_uri');
        }
        return this.data?.redirect_uri;
    }

    get color() {
        return getComputedStyle(document.documentElement).getPropertyValue('--color');
    }
    set color(color: string) {
        document.documentElement.style.setProperty('--color', color);
    }
    get providers() {
        return this.data?.providers.filter(x => this._validProviders.includes(x)) || [];
    }
    get fields() {
        return this.data?.fields || [];
    }
    get logo() {
        return this.data?.logo || LOGO;
    }
    get verify() {
        return this.data?.verify || false;
    }
    get client() {
        return this.data?.client?.name || '';
    }

    async init() {
        if (this.initialized) return;
        if (this._name) {
            await this.getData(this._name);
            this.initialized = true;
        }
    }

    async change(appName: string) {
        this._redirect_uri = '';
        return await this.getData(appName);
    }

    public path(path: string) {
        return `apps/${this.name}/${path}`
    }

    public redirectToIndex() {
        let href = `/index.html?app=${this.name}`;
        if (this.redirectUri) {
            href += `&redirect_uri=${this.redirectUri}`;
        }
        window.location.href = href;
    }

    public async redirectUserToApp() {
        const user = Vars.user;
        if (user) {
            const token = await getRefreshedToken(user.refreshToken)
            if (token) {
                const code = await getGoldenTicket(token.token)
                return this.redirectUserToAppWithCode(code.token)
            }

        }
    }

    public redirectUserToAppWithCode(code: string) {
        const urlRedirect = this.redirectUri;

        if (isIE()) {
            const queryChar = urlRedirect.includes('?') ? '&' : '?';
            handleRoute(`${urlRedirect}${queryChar}code=${code}`);
        } else {
            const url = new URL(urlRedirect);
            url.searchParams.append('code', code);
            handleRoute(url.toString());
        }
    }

    private async getData(appName: string) {
        try {
            const queries = new URLSearchParams();
            if (this._redirect_uri) queries.set('redirect_uri', this._redirect_uri)
            if (this.client_id) queries.set('client_id', this.client_id)
            this.data = await get<SSOApp>(`apps/${appName}?${queries.toString()}`);

            if (this._redirect_uri && this._redirect_uri !== this.data.redirect_uri) {
                setInitError(translate.t('redirect_uri={{url}} er ikke en gydlig omdirigeringslenke for app "{{appName}}"', { url: this._redirect_uri, appName }));
                this.error = 'True';
                return this.data;
            }

            Vars.app = this.data;
            this.initSettings(this.data);
            return this.data;
        } catch (e) {
            this.error = String(e);
            setInitError(translate.t('App med navn "{{appName}}" ikke funnet', { appName }));
        }
    }

    private initSettings(app: SSOApp) {
        if (app.color) {
            this.color = app.color;
        }
        this._redirect_uri = app.redirect_uri;
        setLogos(app.logo || LOGO);
        this.initialized = true;
    }
}

export const DEFAULT_LOCALE = 'no';
export const LOCALE = isIndex ? queries.get('locale') || Vars.locale : Vars.locale;
export const app = new App();

export async function initSSO() {
    if (isIndex || isUpdate) {
        Vars.authenticate = false
        Vars.email = '';
        Vars.scope = queries.get('scope');
    }

    Vars.activeView = 0;
    Vars.locale = LOCALE;
    document.documentElement.setAttribute('lang', Vars.locale);

    await app.init();
}

export async function getApps() {
    try {
        return get<SSOApp[]>('apps');
    } catch (e) {
        setInitError(String(e));
        return [];
    }
}

function setInitError(text: string) {
    const doc = document.getElementById('init-error');
    if (doc) {
        doc.innerText = text;
    }
}