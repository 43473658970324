import {BaseUser, CookieUser, SSOResponse, User} from "./types";
import {app, url, Vars} from "./init";
import {getInputValue} from "./input";
import {createFetchOpts, getGoldenTicket, getRefreshedToken, handleSSOErrors, post} from "./ssoApi";
import {handleRoute} from "./ui";
import {translate} from "./translate";

export function patchUser(user: BaseUser, token: string) {
    const getValue = (key: keyof BaseUser) => {
        const val = getInputValue(key) || user[key];
        return val ? {
            [key]: getInputValue(key) || user[key]
        } : {};
    };

    const userData: Partial<User> = {
        ...getValue('email'),
        ...getValue('firstName'),
        ...getValue('lastName'),
        ...getValue('phone')
    };

    return post<User>('user', userData, token, 'PATCH');
}


export function postUserRegister(email: string, password: string, rest: Omit<Partial<BaseUser>, 'email' | 'password'>) {
    return post<User>(app.path('auth/email/register'), {
        email,
        password,
        ...rest
    });
}


interface ConfirmResponse extends SSOResponse {
    code: string;
}
export function postOauthAuthenticate(code: string, token: string) {
    return post<ConfirmResponse>('oauth2/authenticate', { code }, token);
}

export function oAuthCancel(code: string) {
    window.location.replace(`${url}/oauth2/cancel?code=${code}`)
}
export function oAuthRedirect(code: string) {
    window.location.replace(`${url}/oauth2/redirect?code=${code}`)
}

export function patchUserEmail(email: string, token: string) {
    return post(app.path('auth/email/email'), { email }, token);
}

export function patchUserPassword(oldPassword: string, password: string, token: string) {
    return post(app.path('auth/email/password'), {oldPassword, password}, token);
}

export function postUserForgot(email: string) {
    const body = { url: `${window.location.origin}/reset.html`, email };
    return post(app.path('auth/email/forgot'), body)
}

export async function handleUser(ssoResponse: SSOResponse) {
    const userObj = setUser(ssoResponse);
    await handleUserLogin(userObj, ssoResponse.accessToken.token);
}

export async function verifyEmail(email: string) {
    return post(app.path('auth/email/verify'), { email })
}

export async function handleUserLogin(cookie_user: CookieUser, token?: string) {
    try {
        if (Vars.authenticate) {
            return handleRoute('/auth.html');
        }

        if (!token) {
            token = await getTokenFromUser(cookie_user);
        }

        if (!token) {
            throw new Error('Missing user');
        }

        const { token: code } = await getGoldenTicket(token);
        app.redirectUserToAppWithCode(code);
    } catch (e) {
        Vars.user = null;
        handleRoute('login.html');
    }
}

export async function getTokenFromUser(cookie_user: CookieUser) {
    const res = await getRefreshedToken(cookie_user.refreshToken);
    if (res) {
        return res.token;
    }
    return undefined;
}

export function postUserLogout(token: string) {
    return post('auth/invalidate-refresh-tokens', {}, token, 'GET');
}

export function postTwoFactorVerify(accessToken: string, token: string) {
    return post<{ secret: string; issuer: string; }>('otp/verify', { token }, accessToken, 'POST');
}
export function postTwoFactorInit(token: string) {
    return post<{ secret: string; issuer: string; }>('otp/code', {}, token, 'GET');
}
export async function postTwoFactorInitQR(token: string) {
    const response = await fetch(`${url}/otp/qr`, createFetchOpts({}, "GET", token));
    if (response.status > 399) {
        const data = await response.json();
        throw handleSSOErrors(data);
    }
    try {
        const imageBlob = await response.blob();
        return URL.createObjectURL(imageBlob);
    } catch (e) {
        throw translate.t('Feilet med parsing av data.');
    }
}

export function setUser(ssoResponse: SSOResponse) {
    const userObj: CookieUser = { ...Vars.user || {}, ...ssoResponse.data, } as CookieUser;
    if (ssoResponse.refreshToken?.token) {
        userObj.refreshToken = ssoResponse.refreshToken.token;
        userObj.expires = new Date(ssoResponse.refreshToken.expires).toISOString();
    }

    Vars.user = userObj;
    return userObj;
}

interface UpdateUser {
    firstName: string;
    lastName: string;
    phone: string;
}
export function updateUser(user: CookieUser, update: UpdateUser) {
    const updated: CookieUser = { ...user, ...update };
    Vars.user = updated;
    return updated;
}

export function removeUser() {
    Vars.user = null;
}