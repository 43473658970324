import {bankIDLogo, setBusy, googleButton, microsoftButton} from "./elements";
import {app, url} from "./init";
import {translate} from "./translate";

export function initThirdPartyButtons() {
    for(const provider of app.providers) {
        switch (provider) {
            case "microsoft":
                const microsoft = document.getElementById("microsoft");
                if (microsoft) {
                  microsoft.innerHTML = microsoftButton;
                  microsoft.addEventListener("click", () =>
                    microsoftLogin(microsoft.dataset.type)
                  );
                  microsoft.setAttribute(
                    "aria-label",
                    translate.t("Logg inn med microsoft")
                  );
                }
                break;
            case "google":
                const google = document.getElementById('google');
                if (google) {
                    google.innerHTML = googleButton;
                    google.addEventListener('click', () => googleLogin(google.dataset.type));
                    google.setAttribute('aria-label', translate.t('Logg inn med google'));
                }
                break;
            case 'bankid':
                const bankID = document.getElementById('bankid');
                if (bankID) {
                    bankID.innerHTML = bankIDLogo;
                    bankID.addEventListener('click', () => bankIDLogin(bankID.dataset.type));
                    bankID.setAttribute('aria-label', translate.t('Logg inn med bankid'));
                }
        }
    }
}

export function googleLogin(type = 'register') {
    setBusy(true);
    const redirect = `${window.location.origin}/google.html`;
    window.location.href = `${url}/apps/${app.name}/auth/google?redirect=${redirect}&type=${type}`;
    setTimeout(() => setBusy(false), 5000)
}

export function microsoftLogin(type = "register") {
    setBusy(true);
    const redirect = `${window.location.origin}/microsoft.html`;
    window.location.href = `${url}/apps/${app.name}/auth/microsoft?redirect=${redirect}&type=${type}`;
    setTimeout(() => setBusy(false), 5000);
  }

export function bankIDLogin(type = 'register') {
    setBusy(true);
    const redirect = `${window.location.origin}/bankid.html`;
    window.location.href = `${url}/auth/bankid/login?redirect=${redirect}&type=${type}`;
    setTimeout(() => setBusy(false), 5000)
}