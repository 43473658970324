import {AppScope, SSOError, Token} from "./types";
import {app, url, Vars} from "./init";
import {translate} from "./translate";
import {handleRoute} from "./ui";

interface JsonObject {
    [key: string]: string | number | boolean;
}

export function jsonToURI<T extends JsonObject>(body: T) {
    return Object.keys(body)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
        .join('&');
}

export function createFetchOpts(body = {}, method: 'GET' | 'POST' | 'PATCH' = 'POST', token?: string) {
    const headers = new Headers();
    const opts: RequestInit = {
        method: method,
        body: jsonToURI(body),
        redirect: 'follow',
    };
    headers.append('Content-type', 'application/x-www-form-urlencoded');

    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
        opts.mode = 'cors';
        opts.credentials = 'omit';
    }

    if (method === 'GET') {
        delete opts.body;
    }

    opts.headers = headers;

    return opts
}

export function handleSSOErrors(response: SSOError | string) {
    if (typeof response === 'string') {
        return response;
    }

    switch (response.message || response.error) {
        case 'badRefreshToken':
            return 'badRefreshToken';
        case 'wrongUsernameOrPassword':
            return translate.t('Feil passord. Prøv igjen, eller klikk på glemt passord for å tilbakestille det.');
        case 'nameRequired':
            return translate.t('Trenger navn for å registrere ny bruker');
        case 'userAlreadyExists':
            return translate.t('E-post er allerede registrert. Prøv å logg inn.');
        case 'userNotFound':
            return translate.t('Vi fant deg ikke. Se etter skrivefeil eller registrer deg ved å trykke "Ny bruker".');
        case 'Token is expired':
            return translate.t('Innlogingsøkten timet ut. Prøv igjen.');
        case 'needOldPassword':
        case 'missingEmailOrPassword':
        case 'missingTokenOrPassword':
            return translate.t('Mangler data for spørring. Fyll inn alle felt');
        case 'tokenIsNotValidUpdateToken':
            return translate.t('Nøkkel er ikke gyldig. Spør om en ny nøkkel for å oppdatere info.');
        case 'failedToGeneratePassword':
        case 'failedToCreateUser':
            return translate.t('Klarte ikke lage bruker. Prøv igjen.');
        case 'token contains an invalid number of segments':
            return translate.t('Spørring feilet. Prøv operasjonen igjen.');
        case 'emailIsEqual':
            return translate.t('E-post er lik den registrerte.');
        case 'emailTaken':
            return translate.t('En annen bruker er allerede registrert med denne e-posten. Prøv en annen.')
        case 'tokenAlreadyUsed':
        case 'token_already_used':
            return translate.t('Oppdaterinslinken er allerede brukt eller utgått.');
        case 'invalid code':
        case 'invalid authorize code':
        case 'otpExpired':
            return translate.t('Kode er ygyldig eller utgått');
        case 'tokenRequired':
            return translate.t('Mangler OTP kode');
        default:
            return translate.t('Kommunikasjon med server feilet. Prøv igjen.');
    }
}

export function get<T extends object>(path: string, opts?: RequestInit) {
    return new Promise<T>((res, rej) => {
        fetch(`${url}/${path}`, opts).then(async response => {
            try {
                const data = await response.json();
                if (response.status > 399) {
                    return rej(handleSSOErrors(data))
                }
                res(data);
            } catch (e) {
                rej(translate.t('Feilet med parsing av data.'));
            }
        }).catch(e => {
            console.error(e);
            rej(translate.t('Ingen kontakt med server'));
        });
    });
}
export function post<T extends object>(path: string, body: object, token?: string, method: 'GET' | 'POST' | 'PATCH' = 'POST') {
    return new Promise<T>((res, rej) => {
        fetch(`${url}/${path}`, createFetchOpts(body, method, token)).then(async response => {
            try {
                const data = await response.json();

                if (response.status === 303 && data.url) {
                    handleRoute(data.url);
                    return;
                }
                if (response.status > 399) {
                    return rej(handleSSOErrors(data))
                }
                res(data);
            } catch (e) {
                rej(translate.t('Feilet med parsing av data.'));
            }
        }).catch(e => {
            console.error(e);
            rej(translate.t('Ingen kontakt med server'));
        });
    });
}

export async function getRefreshedToken(refreshToken: string) {
    try {
        return await post<Token>(`auth/access-token`, {refreshToken});
    } catch (e) {
        Vars.user = null;
        return handleRoute('login.html');
    }
}

export function getGoldenTicket(accessToken: string) {
    const params = new URLSearchParams({
        audience: app.name,
        scope: Vars.scope,
    });
    return post<Token>(`auth/golden-ticket?${params.toString()}`, {}, accessToken, 'GET');
}

export function getScopes(scope: string): Promise<AppScope[]> {
    const query = new URLSearchParams({ scope });
    return get<AppScope[]>(`scopes?${query.toString()}`);
}