import {translate} from "./translate";
import {app} from "./init";

export function validatePasswords(password: string, passwordC: string) {
    if (password.length < 6) {
        return translate.t('Passordet må være lengre en {{length}} karakterer', {length: 6});
    }

    if (password !== passwordC) {
        return translate.t('Passordene samsvarer ikke');
    }

    return null;
}

export function initInputEvents() {
    const inputs = document.getElementsByTagName('input');
    // @ts-ignore
    Object.keys(inputs).forEach(key => inputs[key].removeEventListener('focus', handleInputClick));
    Object.keys(inputs).forEach(key => {
        const input: any = inputs[key as any];
        if (input.type === 'checkbox') return;
        input.addEventListener('focus', handleInputClick)
        if (input.value !== '') {
            const label = input.nextElementSibling as HTMLLabelElement;
            setInputActive(input, label);
        }
    });

    if (document.activeElement && (document.activeElement instanceof HTMLInputElement)) {
        const input = document.activeElement;
        const label = input.parentNode!.children[1] as HTMLLabelElement;
        setInputActive(input, label);
    }
}

export function handleInputClick(e: MouseEvent) {
    const input = e.target as HTMLInputElement;
    const label = input.nextElementSibling as HTMLLabelElement;
    setInputActive(input, label);
}

function setInputActive(input: HTMLInputElement, label: HTMLLabelElement) {
    label.style.transform = "translate3d(-3px, -25px, 0) scale(.9)";
    label.style.color = app.color;

    function removeTransform() {
        if (input.value === "") {
            label.style.transform = "";
            label.style.color = "";
        }
        input.removeEventListener('blur', removeTransform);
    }

    input.addEventListener('blur', removeTransform);
}

export const setInputValue = (id: string, value: string, aria?: string) => {
    const doc = document.getElementById(id)! as HTMLInputElement;
    doc.value = value || '';
    if (aria) doc.setAttribute('aria-label', aria);
};

export const getInputValue = (id: string) => {
    const doc = document.getElementById(id)! as HTMLInputElement;
    return doc ? doc.value : '';
};

export const languageSelect = `
    <div style="display: flex; align-items: center;">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path d="M12.87,15.07L10.33,12.56L10.36,12.53C12.1,10.59 13.34,8.36 14.07,6H17V4H10V2H8V4H1V6H12.17C11.5,7.92 10.44,9.75 9,11.35C8.07,10.32 7.3,9.19 6.69,8H4.69C5.42,9.63 6.42,11.17 7.67,12.56L2.58,17.58L4,19L9,14L12.11,17.11L12.87,15.07M18.5,10H16.5L12,22H14L15.12,19H19.87L21,22H23L18.5,10M15.88,17L17.5,12.67L19.12,17H15.88Z" />
        </svg>
        <h3 style="padding-left: .5rem;">${translate.w('Språk')}</h3>
    </div>
    <div>
        <select id="language-select">
            <option value="no">
                Norsk
            </option>
            <option value="en">
                English
            </option>
        </select>
    </div>
`;